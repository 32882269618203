import React, { useState } from "react"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { AdminOfferType } from "@jobintrans/base-ui.types.api"
import { Outlet, useNavigate } from "react-router-dom"
import { AdminOffersService } from "@jobintrans/base-ui.services.api-service"
import styled from "styled-components"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { LabelH2, P } from "@jobintrans/base-ui.components.atoms.typography"
import { translate } from "@jobintrans/base-ui.data"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Button from "@jobintrans/base-ui.components.atoms.button"
import OfferDetailsApplications from "./OfferDetailsApplications"
import { SentryService } from "services/SentryService"
import { useCustomQuery } from "hooks/useCustomQuery"
import Status from "components/atoms/Status/Status"
import { formatDate } from "utils/date"
import Link from "components/atoms/Link/Link"
import TransactionBlock from "components/shared/TransactionBlock"
import PlatformLink from "components/shared/PlatformLink"
import { InfoContent } from "components/shared/InfoComponents"
import { AdInfo } from "components/shared/OfferBlock"
import ToolAddAd from "views/Admin/Tools/ToolAddAd"

const OfferDetails = ({ loading, data }: { loading?: boolean; data?: AdminOfferType }) => {
  const navigate = useNavigate()

  const [showApplications, setShowApplications] = useState(false)

  const { isLoading: isLoadingCount, data: count } = useCustomQuery<number>(
    { key: ["count_applications", data?.id] },
    () => AdminOffersService.countApplications(`${data?.id}`),
  )

  const boostOffer = async () => {
    try {
      await AdminOffersService.boost(`${data?.id}`)
      window.alert(`Ogłoszenie ${data?.name} zostało podbite na górę listy!`)
    } catch (e) {
      SentryService.error("[ERROR AdminOffersService.getOffer]:", e)
    }
  }

  const deactivatedOffer = async () => {
    if (confirm(`Czy na pewno chcesz dezaktywować ogłoszenie ${data?.name}?`) == true) {
      try {
        await AdminOffersService.deactivated(`${data?.id}`)
        window.alert(`Ogłoszenie ${data?.name} zostało dezaktywowane!`)
      } catch (e) {
        SentryService.error("[ERROR AdminOffersService.deactivated]:", e)
      }
    }
  }

  const deleteOffer = async () => {
    if (confirm(`Czy na pewno chcesz usunąć ogłoszenie ${data?.name}?`) == true) {
      try {
        await AdminOffersService.permanentlyDelete(`${data?.id}`)
        window.alert(`Ogłoszenie ${data?.name} zostało usunięte!`)
      } catch (e) {
        SentryService.error("[ERROR AdminOffersService.permanentlyDelete]:", e)
      }
    }
  }

  if (loading || !data)
    return (
      <Row justifyContent="center" alignItems="center" mt="XL">
        <Spinner />
      </Row>
    )

  return (
    <>
      <Wrapper>
        <Box marginBottom="M">
          <Status label={data.status} />
          <Box marginTop="XXS">
            <PlatformLink status={data.status === "active"} to={`/offers/${data.id}`} />
          </Box>
        </Box>
        <Content>
          <Column>
            <InfoContent>
              <P label="Stanowisko" margin="0">
                {translate(data.category)}
              </P>
              <P label="Kategoria" margin="0">
                {translate(data.subcategory)}
              </P>
              <P label="Data utworzenia" margin="0">
                {formatDate(data.dtCreated)}
              </P>
              <P label="Data wygaśnięcia" margin="0">
                {data.expiredDate ? formatDate(data.expiredDate) : "-"}
              </P>
              <P label="Ilość aplikacji" margin="0">
                {count}
              </P>
              <P label="Język ogłoszenia" margin="0">
                {data.language.toUpperCase()}
              </P>
            </InfoContent>
            <Box>
              <Button type="secondary" width="100%" icon="boost" onClick={boostOffer}>
                Podbij ogłoszenie
              </Button>
              <Link
                type="secondary"
                width="100%"
                icon="target"
                marginTop="XS"
                to={`/panel/offers/${data.id}/analytics`}
              >
                Zobacz statystyki
              </Link>
              <Button
                width="100%"
                icon="check-square"
                marginTop="XS"
                onClick={() => setShowApplications(true)}
              >
                Zobacz kandydatów
              </Button>
              <Button
                type="secondary"
                marginTop="M"
                width="100%"
                icon="trash"
                onClick={deactivatedOffer}
              >
                Dezaktywuj ogłoszenie
              </Button>
              <Button
                type="secondary"
                width="100%"
                icon="trash"
                background="red"
                color="white"
                hoverBackground="red"
                hoverColor="white"
                marginTop="XS"
                onClick={deleteOffer}
              >
                Usuń ogłoszenie
              </Button>
            </Box>
            <Accordion title="Informacje o profilu">
              <LabelH2 marginBottom="XS">{data.profile.name}</LabelH2>
              <Link icon="eye" type="secondary" to={`/panel/profiles/${data.profile.id}`}>
                Wyświetl szczegóły
              </Link>
            </Accordion>
          </Column>
          <Column>
            <Accordion title="Informacje o transakcjach">
              {data.transactions.map(transaction => (
                <TransactionBlock
                  key={transaction.id}
                  transaction={transaction}
                  refresh={() => {}}
                />
              ))}
            </Accordion>
            {data.ads?.google && <AdInfo type="google" isAdmin data={data.ads?.google} />}
            {data.ads?.facebook && <AdInfo type="facebook" isAdmin data={data.ads?.facebook} />}
            <ToolAddAd id={data.id} />
          </Column>
        </Content>
      </Wrapper>
      <OfferDetailsApplications
        id={data?.id}
        show={showApplications}
        setShow={setShowApplications}
      />
      <Outlet />
    </>
  )
}

export default OfferDetails

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.M};
`

const Content = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.M};
`

const Wrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.space.M};
`
